.sub-section-header {
    text-align: center;
    margin-bottom: 4rem;

    .subscription-benefits & {
        p {
            @include make-container(52.125rem);

        }
    }

    .subscription-lead & {
        p {
            @include make-container(48.125rem);

        }
    }


}