/*  CSS Grid and FLexbox
*/
/*--------------------------------------------------------*/
/*------------------- Default Template Grid --------------*/
/*--------------------------------------------------------*/

.main-nav {
    grid-area: mainNav;

}

.page-main-content {
    grid-area: mainContent;
    grid-column-start: 1;
    grid-column-end: 4;
}

.footer {
    grid-area: footerMain;
}

.footer-bottom {
    grid-area: footerBottom;
}

main {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 4rem auto auto auto;
    grid-template-areas:
        " mainNav mainNav  mainNav "
        "  mainContent mainContent  mainContent"
        " footerMain footerMain footerMain"
        "footerBottom footerBottom footerBottom";

    @media #{$mq-tablet-up} {
        grid-template-rows: 8rem minmax(100vh, auto) auto minmax(43px, 80px);
    }

}

.main_nav {
    grid-area: mainNav;
}

.page-main-content {
    grid-area: mainContent;
}

footer {
    grid-area: footerMain;
    grid-row-end: 4;
}

.footer-bottom {
    grid-area: footerBottom;
    grid-row-start: 4;
}

/*------------------- Footer Grid --------------*/

.footer {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 3fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
        "footer-top footer-top footer-top footer-top"
        "footer-middle footer-middle footer-middle footer-middle";

}

.footer-top {
    grid-area: footer-top;
}

.footer-middle {
    grid-area: footer-middle;
}




/*------------------- Footer Flex --------------*/

.company-links {
    @include margin(initial, auto, auto, 2rem);
    align-self: auto;
    justify-self: center;
    justify-content: space-between;
}

.footer-top {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .company-links {
        flex-basis: 50%;
    }

    .instagram-widget {
        flex-basis: 100%;
    }

    @media #{$mq-tablet-up} {
        .company-links {
            flex-basis: 19%;

            &:nth-of-type(2) {
                flex-basis: 28%;
            }
        }

        .instagram-widget {
            flex-basis: 53%;
        }
    }

    @media #{$mq-desktop-up} {



        .company-links {
            flex-basis: 16%;

            &:nth-of-type(2) {
                flex-basis: 60%;
            }
        }

        .instagram-widget {
            flex-basis: 23%;

            ul {
                display: flex;
                flex-wrap: wrap;
            }
        }

    }

    // @media #{$mq-desktop-up} {

    // }





}

.footer-middle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;


    .brand-footer {
        flex-basis: 100%;
    }

    .about-footer {
        flex-basis: 100%;
    }

    .submit-inquiry-footer {
        flex-basis: 100%;
    }

    .social {
        align-self: center;
        flex-basis: 100%;
    }

    @media #{$mq-tablet-up} {
        .submit-inquiry-footer {
            flex-basis: 50%;
        }

        .social {
            align-self: center;
            flex-basis: 50%;
        }
    }

    @media #{$mq-desktop-up} {
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .brand-footer {
            flex-basis: 16%;
        }

        .about-footer {
            flex-basis: 28%;
        }

        .submit-inquiry-footer {
            flex-basis: 25%;
            text-align: center; // move later
        }

        .social {

            flex-basis: auto;
        }

    }


}

.footer-menu-horizontal {

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media #{$mq-tablet-up} {
        flex-direction: row;
    }

}

/*--------------------------------------------------------*/
/*------------------- Index Grid -------------------------*/
/*--------------------------------------------------------*/
.page-home {
    display: grid;
    grid-template-rows: repeat(5, auto);
    grid-template-areas:
        "hero"
        "slider-1"
        "agency"
        "how"
        "slider-2";
}

.hero-home {
    grid-area: hero;
    grid-row-start: 1;
}

.subscriptions-slider {
    grid-area: slider-1;
}

.agency-exp {
    grid-area: agency;
}

.how-it-works {
    grid-area: how;
}

.agency-slider {
    grid-area: slider-2;
}


/*--------------------------------------------------------*/
/*------------------- Sign Up Page Grid --------------*/
/*--------------------------------------------------------*/

.page-sign-up {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 80%) 1fr;
    grid-template-rows: 4rem 2fr 1fr;
    grid-template-areas:
        " . . . "
        " . header . "
        " . bricksform .";

    header {
        grid-area: header;
    }

    .bricks-sign-up {
        grid-area: bricksform;

    }

    .sign-up-form {
        display: flex;
        flex-direction: column;

        .form-row {
            justify-content: space-around;
        }

        button {
            align-self: center;
        }
    }

    @media #{$mq-tablet-up} {
        grid-template-rows: 8rem auto 1fr;
    }

    @media (min-width : 1920px) {
        grid-template-rows: 1fr 2fr 1fr;
    }
}

/*--------------------------------------------------------*/
/*------------------- Portfolio Page Grid --------------*/
/*--------------------------------------------------------*/
.page-portfolio {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "portfolio-slider portfolio-slider"
        "portfolio-gallery portfolio-gallery";
}

.slider-portfolio {
    grid-area: portfolio-slider;
}

.portfolio-gallery {
    grid-area: portfolio-gallery;
}

/*------------------- Portfolio Gallery  Grid --------------*/

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 100%));
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-auto-flow: dense;
    grid-gap: 1px;

    .gallery-item {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (min-width:480px) {
            grid-area: 1/1/ span 2 span 2;
        }

        &:nth-child(6n),
        &:nth-child(3n) {
            grid-row: span 2;
        }
    }
}


/*--------------------------------------------------------*/
/*------------------- Section - Agency-exp --------------*/
/*--------------------------------------------------------*/

.agency-exp {
    display: flex;

    .home-cta {
        flex-basis: 50%;
        padding: 0 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .home-cta-img {
        flex-basis: 50%;

        img {
            width: 100%;
        }
    }

    @media #{$mq-tablet-down} {
        flex-direction: column;

        .home-cta {
            flex-basis: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;

        }

        .home-cta-img {
            flex-basis: 100%;

        }

    }

    @include media-breakpoint-down(md) {
        @include margin(3rem, null, 3rem, null);
        @include padding(null, 2rem, null, 2rem);
        max-width: 100%;
    }

}