@include font-face("primeregular",'Prime-Regular'); 
@include font-face("primelight",'Prime-Light'); 

body{
    font-family:$font-family-primary;
}
h1,
h2,
h3,
h4,
h5,
h6{
    font-family: $font-family-primary;
    color: text-colors("white-copy");
}
h1{
    @include fontSize(60px);
    line-height: 1;
    font-family: $font-family-secondary;
}
h2{
    @include fontSize(49px);
    line-height: 1.2;     
}
h3{
    @include fontSize(30px);
    line-height: 1.266;
}
h4{
    @include fontSize(20px);
    line-height: 1.3;
}
h5{
    @include fontSize(16px);
    line-height: 1.3125;
}
h6{
    @include fontSize(16px);
    line-height: 1.6875;
    color: text-colors("grey-copy");
}
p{
    @include fontSize(16px);
    margin: 0;
    line-height: 1.6875;
    color: text-colors("grey-copy");     
}

ul {
    list-style-type: none;
    padding-inline-start: 0;
  }