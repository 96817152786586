hr {
   border-top: 4px solid $red;
   margin-inline-start: 0;
   margin-block-start: 0;
   margin-bottom: 1.5rem;

   .hero-text & {
      width: 17.6625rem;
      @include media-breakpoint-down(sm){
         width: 11.6625rem;
     }
   }

   .how-it-works & {
      width: 17rem;
      @include media-breakpoint-down(sm){
         width: 11.05rem;
     }      
   }

   .slide-header & {
      width: 18.7rem;
      @include media-breakpoint-down(sm){
         width: 12rem;
     }       
   }
}