.agency-exp {
    @media #{$mq-tablet-down} {

        padding: 0;

        .home-cta {
            padding: 0 1.5rem;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }
    }
}