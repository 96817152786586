.main_nav {
    @include fontSize(14px);
    @include padding(1rem, 1rem, 1rem, 1rem);
    display: flex;
    font-family: $font-family-primary;
    text-transform: uppercase;

    a {
        color: text-colors("white-copy");

        &:hover {
            text-decoration: none;
        }
    }

}

.main_nav-link-brand {
    @include fontSize(31px);
    line-height: 1.23;
    font-family: $alt-heading-font;
    align-self: center;
}

.main_nav-link {
    width: auto;
    
    white-space: nowrap;
    @media (min-width : 768px) {
        padding: 0 2rem;
    }
}

.main_nav-items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 6rem;
}

.main_nav-items--right {
    margin-left: auto;
}

.main_nav-link-toggle {
    display: none;
}

@media only screen and (max-width: 768px) {

    .main_nav-items,
    .main_nav {
        flex-direction: column;
        height: 25rem;
    }
    .main_nav-link-brand {
        @include fontSize(32px);
        align-self: auto;
    }
    .main_nav-items {
        display: none;
        align-items: start;
    }

    .main_nav-items--right {
        margin-left: 0;
    }

    .main_nav-link {
        @include fontSize(22px);
        width: auto;
        flex-basis: auto;

    }

    .main_nav-toggleShow {
        background-color: bg-colors("black-background");
        display: flex;
        z-index: 999;
    }

    .main_nav-link-toggle {
        
        align-self: flex-end;
        display: initial;
        position: absolute;
        cursor: pointer;
    }
}





// .main_nav {
//     @include fontSize(14px);
//     @include padding(0, 0, 0, 0);
//     background-color: bg-colors("off-black");
//     font-family: $font-family-primary;
//     text-transform: uppercase;
//     line-height: 1;
//     height: 8.75rem;

//     .navbar {
//         padding-top: 2.75rem;

//         @include media-breakpoint-down(lg) {
//             padding: .9375rem 0 0 0;
//         }
//     }
//     .navbar-nav{
//         margin-right: 21rem;

//         @media #{$mq-xlarge} {
//             margin-right: 9rem;
//           }

//     }
//     .navbar-brand {
//         @include fontSize(31px);
//         line-height: 1.23;
//         font-family: $alt-heading-font;

//         @include media-breakpoint-down(sm) {
//             padding-left: .9375rem;
//         }
//         @include media-breakpoint-between(sm,lg) {
//             padding: 1rem 1.5rem;
//         }
//     }

//     ul {
//         width: 65%;

//         li {
//             float: left;
//             padding: 0 12px;
//             width: 45%;
//             text-align: center;

//             @include media-breakpoint-down(lg) {
//                 text-align: left;
//             }

//             a {
//                 margin-top: .8rem;
//                 white-space: nowrap;
//             }
//         }

//         @include media-breakpoint-down(lg) {
//             width: 100%;
//             padding-left: 1rem;
//         }
//     }

//     @include media-breakpoint-down(lg) {
//         @include fontSize(30px);
//         height: 5.5rem;

//     }
//     @include media-breakpoint-down(sm) {
//         @include fontSize(22px);
//         height: 5.5rem;

//     }
// }
// .navbar-toggler{
//     margin-right: 1rem;
// }
// .navbar-collapse {
//     z-index: 1001;
//     padding-bottom: 25px;
//     background-color: #101010;
// }


.subscriptions-navigator {
    padding: 1rem;
}

.subscriptions-nav-bar {
    margin-left: 15rem;
}