//Text Colors

.white-copy {
    color: text-colors("white-copy");
}

.black-copy {
    color: text-colors("black-copy");
}

.dark-grey-copy {
    color: text-colors("dark-grey");
}

.grey-copy {
    color: text-colors("grey-copy");
}

.red-copy {
    color: text-colors("red-copy");
}

.light-grey-copy {
    color: text-colors("light-grey");
}


.heading-level-1 {
    @extend h1;

    .hero-text & {
        font-family: $font-family-secondary;
    }

    .about-header & {
        font-family: $font-family-secondary;

        @include media-breakpoint-down(md) {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }

    .slider-about & {
        color: text-colors("white-copy");
    }
}

.heading-level-2 {
    @extend h2;

    .home-cta & {
        @extend .black-copy;
        margin-bottom: 2rem;
        // @include mq('desktop') {
        //     @include fontSize(50px);
        // }
        // @include mq('ipad-pro-p') {
        //     @include fontSize(35.2px);
        // }

        // @include mq('ipad-pro-ls') {
        //     @include fontSize(40px);
        // }
        @media #{$mq-tablet-down} {
            @include fontSize(32px);
            margin-bottom: 0;

        }
    }

    .how-it-works & {
        margin-top: 7rem;
        font-family: $font-family-secondary;

        @include media-breakpoint-down(sm) {
            margin-top: 4rem;
        }
    }

    .about-agency-exp & {
        font-family: $font-family-primary;

        // @media #{$mq-tablet-up} {

        // }
        // @media #{$mq-desktop-up} {
        // }
    }

    .business-subscription &,
    .subscription-lead &,
    .subscription-benefits &,
    .subscription-benefits-cards & {
        padding-top: 5rem;
        font-family: $font-family-secondary;
        color: text-colors("black-copy");
        text-align: center;
        line-height: 1.2;
        letter-spacing: .10625rem;
        padding-bottom: 1rem;
    }

    .project-slide-lft & {
        @include fontSize(28.8px);
        font-family: $font-family-secondary;

        @media (min-width: 768px) {
            @include fontSize(36px);
        }

        @media (min-width: 1024px) {
            @include fontSize(49px);
        }
    }

}

.heading-level-3 {
    @extend h3;

    .slider-subscriptions & {
        @extend .red-copy;
        font-family: $font-family-primary;
    }

    .subscription-benefits-cards & {
        @extend .black-copy;
        letter-spacing: 1.1px;
        line-height: 1.2;
        margin-bottom: 1rem;
    }
}


.heading-level-4 {
    @extend h4;

    .hero-text & {
        margin-bottom: 2rem;
    }

    .subscription-benefits-cards & {
        font-family: $font-family-primary;
        line-height: 1.2;
        letter-spacing: .8px;
        padding-top: 1rem;
    }
}

.heading-level-5 {
    @extend h5;

    .footer-middle & {
        @include fontSize(28px);

        @include media-breakpoint-down(md) {
            @include fontSize(24px);
        }

        @include media-breakpoint-between(lg, xl) {
            @include fontSize(16.8px);
            margin-bottom: .7rem;
        }
    }
}

.heading-level-6 {
    @extend h6;
}

.lead-level-1 {
    @include fontSize(35px);
    line-height: 1.23;

    .hero-text & {
        @extend .white-copy;
        @include margin(30px, null, 30px, null);

        @include media-breakpoint-down(sm) {
            @include fontSize(24px);
        }
    }


    .about-header & {
        font-family: $font-family-secondary;
        color: text-colors('white');
        margin-top: 8rem;
        line-height: 1.43;

        @include media-breakpoint-down(md) {
            @include fontSize(22.4px);
        }
    }



    .slider-about & {
        font-family: $font-family-secondary;
        line-height: 1.43;
        color: text-colors('white');
    }
}

.lead-level-2 {
    @include fontSize(25px);

    .home-cta & {
        @include margin(1.875rem, null, 2.8125rem, null);
        font-family: $font-family-secondary;
        line-height: 1.54;

        @include media-breakpoint-down(sm) {
            @include fontSize(19px);
            @include margin(1rem, null, 2rem, null);
        }
    }

    .about-agency-exp & {
        font-family: $font-family-secondary;
        line-height: 1.54;

    }

    .slider-subscriptions & {
        font-family: $font-family-secondary;
        line-height: 1.64;

        @include media-breakpoint-down(sm) {
            @include fontSize(19px);
        }
    }

    .hero-image .hero-text & {

        font-family: $font-family-secondary;
        color: text-colors("white");
        line-height: 1.12;

    }

}

.lead-level-3 {
    @include fontSize(20px);

    .slider-subscriptions & {
        font-family: $font-family-secondary;
        line-height: 1.72;
    }

    .business-subscription &,
    .subscription-lead &,
    .subscription-benefits &,
    .subscription-benefits-cards & {
        font-family: $font-family-secondary;
        line-height: 1.4;
        text-align: center;
        letter-spacing: .0375rem;
        max-width: 830px;
        margin: 0 auto;
    }
}

.lead-level-4 {
    @include fontSize(16px);
}

.lead-level-5 {
    @include fontSize(14px);

    .subscription-disclaimers & {
        font-family: $font-family-secondary;
        padding-bottom: 8rem;
    }

    .footer-middle & {
        @include fontSize(24px);
        font-family: $font-family-secondary;

        @include media-breakpoint-down(md) {
            @include fontSize(16.8px);
        }

        @include media-breakpoint-between(lg, xl) {
            @include fontSize(16px);
        }
    }
}

.lead-level-6 {
    @include fontSize(12px);
}

/*--------------------------------------------------------*/
/*------------------- Globals --------------------------*/
/*--------------------------------------------------------*/

/*----------------------footer---------------------------*/






/*--------------------------------------------------------*/
/*------------------- HOME PAGE --------------------------*/
/*--------------------------------------------------------*/


//HERO  - Home Page Lead banner
.hero-home {
    .hero-text {
        padding-top: 10rem;

        button {
            margin-top: 20px;
        }

        .lead-level-1 {
            max-width: 50rem;
        }

        @include media-breakpoint-down(lg) {
            padding-top: 6rem;
        }

        @include media-breakpoint-down(md) {
            padding-top: 2rem;
        }
    }
}

.how-it-works {}

/*--------------------------------------------------------*/
/*------------------- SUBSCRIPTION PAGES --------------------------*/
/*--------------------------------------------------------*/

.skip {
    @include margin(4rem, null, null, 3rem);

    p {

        text-align: right;

    }
}

.inline-link {
    color: #707070;

    .subscriptions-nav-bar & {
        float: right;
        margin-right: 10rem;
        margin-top: 1rem;
    }
}



/*--------------------------------------------------------*/
/*------------------- fix --------------------------*/
/*--------------------------------------------------------*/
.info-container {
    text-align: center;

    .text-pattern--sign-up {
        @include make-container(1255px);
        padding: 5rem 0 3rem;

        @media screen and (min-width: $bp-xsmall-p) and (max-width: $bp-xsmall-l) {
            padding: 1rem 0;
        }

        h1 {
            @include fontSize(100px);
            margin-bottom: 3rem;

            @media screen and (min-width: $bp-xsmall-p) and (max-width: $bp-xsmall-l) {
                @include fontSize(48px);
            }
        }

        p {
            @include fontSize(35px);
            line-height: 1.57;
            font-family: $font-family-secondary;

            @media screen and (min-width: $bp-xsmall-p) and (max-width: $bp-xsmall-l) {
                @include fontSize(19px);
            }
        }
    }
}

.header-xlarge-white {
    @extend .white-copy;
    @include fontSize(65px);
    text-transform: uppercase;

}

.header-large-white {
    @extend .white-copy;
    @include fontSize(50px);

}

.header-large-black {
    @extend .black-copy;
    @include fontSize(50px);
    font-family: $alt-heading-font;

}

.header-red {
    @extend .red-copy;
    @include fontSize(27px);
    line-height: 1.22;
    font-family: $alt-heading-font;

}

.lead-large-white {
    @extend .white-copy;
    @include fontSize(35px);
    font-family: $alt-heading-font;
    margin-bottom: 15px;
}

.lead-large-black {
    @extend .black-copy;
    @include fontSize(35px);
    margin-bottom: 15px;
    line-height: 1.54;
}

.lead {
    .glide & {
        @include fontSize(32px);
    }

    .slide-header & {
        @include fontSize(22px);
        line-height: 1.64;
    }
}





//Home Page CTA
.agency-exp {

    .cta-home-copy {
        @include make-container(715px);
        @include padding(6.5rem, 0, 0, 0);
    }

    .cta-home-img {
        background-color: $offBlack;

        img {
            width: 95%;
            margin-left: 40px;
        }
    }

    h3 {
        margin-bottom: 45px;
    }
}












// Subscription Slider
.subscription-description {
    @include fontSize(24px);
}

.subscription-pricing {
    @include fontSize(49px);
    margin-block-end: -30px;
    position: relative;

    p {
        display: inline;

        &:nth-of-type(2) {
            position: relative;
            left: 7rem;
            top: -3.75rem;
        }
    }

    span {

        //display:block; 
        &:nth-of-type(1) {
            @include fontSize(18px);

        }

        &:nth-of-type(2) {
            @include fontSize(22px);
            //top: -.60rem;
            //left: .25rem;
            // position: relative;       
        }

        display: block;
        position: relative;
        top: -3.8rem;
        left: 8.5rem;
        line-height: 1.3;
    }
}