/*
FONT-FACE RULE
Example
@include font-face($font-family, $url);

*/
@mixin font-face($font-family, $url) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('../webfonts/#{$url}.eot');
		src: url('../webfonts/#{$url}.eot?#iefix') format('embedded-opentype'),
		     url('../webfonts/#{$url}.woff') format('woff'),
		     url('../webfonts/#{$url}.ttf') format('truetype'),
		     url('../webfonts/#{$url}.svg') format('svg');
	}
}


/*
When using ::before and ::after you'll always need these three, so we're saving two lines of code every time you use this.
Exmaple
div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

/* 
ADD VENDOR PREFIXES
This mixin takes two arguments that you need to define when you call it with the @include rule. The first is the name of the CSS property, and the second is its value.
Example
div {
   @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
}
*/
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
 }
 /*
 VERTICAL CENTERING
 Example:
 div {
   @include vertical-center();
}
 
 */
 @mixin vertical-center {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
 }

 /*
 RETINA-READY IMAGES
 o use this mixin, you need to provide two versions of the same image, one in single size (1x) and one in double size (2x). You can use this mixin to provide a retina-ready background image for any HTML element.
 Example:You need to specify three arguments for this mixin: the file path to the retina image (2x) and its width and height.

 .image {
    background: url("my-image.png") no-repeat;
    @include retina-image("my-image2x.png", 1000px, 500px);
}
 */
 @mixin retina-image($image, $width, $height) {
   @media (min--moz-device-pixel-ratio: 1.3),
   (-o-min-device-pixel-ratio: 2.6/2),
   (-webkit-min-device-pixel-ratio: 1.3),
   (min-device-pixel-ratio: 1.3),
   (min-resolution: 1.3dppx) {
       background-image: url($image);
       background-size: $width $height;
   }
}

/*
Padding & Margins
Exmaple:
Usage definition
@include padding(top, right, bottom, left);
@include margin(top, right, bottom, left);

Usage 1
@include padding(1px, 2px, 3px, 4px,);
@include margin(1px, 2px, 3px, 4px);

// Usage 2 (with null properties)
@include padding(1px, null, 3px, 4px);
@include margin(1px, 2px, null, 4px);
*/

//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
   padding-top: $top;
   padding-right: $right;
   padding-bottom: $bottom;
   padding-left: $left;
 }
 //Margin mixin
 @mixin margin($top, $right, $bottom, $left) {
   margin-top: $top;
   margin-right: $right;
   margin-bottom: $bottom;
   margin-left: $left;
 }
//Image replacement... background image
 @mixin img-replace ($img, $w, $h, $bg-pos:center, $bg-sz:cover, $disp:block) {
   background-image: url($img);
   background-position: $bg-pos ;
   background-size:$bg-sz ;
   background-repeat: no-repeat;
   width:$w;
   height:$h;
   display:$disp;
 }

/*Transforms
example: .box { @include transform(rotate(30deg)); }
*/
 @mixin transform($property) {
   -webkit-transform: $property;
   -ms-transform: $property;
   transform: $property;
 }
 
