/* The hero image */
.hero-home {
  background-image: url(../images/img-home-hero.png);

  /* Set a specific height */
  height: 724px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include media-breakpoint-down(md) {
    height: 68vh;
  }
}

.hero-image{
  position: relative;
  background-color: #303030;
  .hero-text{
    position: absolute;
    top: 8rem;
    max-width: 40rem;
    margin-left: 16rem;
    color: text-colors("white");
  }
}
