// ----- BRICKS custom settings -----
@import "normalize";
@import "settings";
@import "mixins/rem";


// ----- Importing all Bootstrap styles -----
@import "node_modules/bootstrap/scss/bootstrap";

// ----- Custom mixins -----
@import "mixins/helpers";
@import "mixins/responsive";
@import "mixins/colors";

// @import "mixins/border";
@import "mixins/containers";
// @import "mixins/header";


// ----- Base typograhphy ----- 
@import "typography";
@import url("https://use.typekit.net/mpz2bis.css");
@import "fonts/fontawesome";
@import "fonts/solid";


// ----- Component specific styles -----
@import "components/layout";
@import "components/global";
@import "components/text-patterns";
@import "components/icons";
@import "components/nav";
@import "components/footer";
@import "components/banner";
@import "components/jumbotron";
@import "components/headers";
@import "components/modals";
@import "components/section";
@import "components/cards";
@import "components/tables";
@import "components/slider";
@import "components/hr";
@import "components/images";

// ----- Form specific styles -----
@import "forms/forms";
 @import "forms/buttons";
// @import "forms/tooltip";
// @import "forms/checkbox";


// ----- Page specific styles -----
@import "pages/home";
@import "pages/bricks-platform";
@import "pages/brighter-business";
@import "pages/business-subscriptions";
@import "pages/about";
@import "pages/sign-up-form";



// ----- Vendor styles -----
// @import "vendors/slider/owl-carousel";