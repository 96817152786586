.about-header{
    .description-why-bricks-lft,
    .description-why-bricks-rgt{
        @include media-breakpoint-down(md) {
         @include padding(2rem,3rem,2rem,3rem);
        }
    }
}
.description-why-bricks-rgt,
.description-why-bricks-lft{
    padding:10rem!important;  
}
.description-why-bricks-rgt{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../images/img-about-pupjoy.jpg);
  
    /* Set a specific height */
    height: 768px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    .lead-level-1{
        margin-top: 11rem; 
    }
    @include media-breakpoint-down(md) {
        height: auto;
        background-position: center;
        .lead-level-1{
            margin-top: 0; 
        }        
    }
}



.about-agency-exp{
    @include make-container(1515px);
    padding-top: 160px;
    padding-bottom: 160px;

    .call-out-header,
    .call-out-left,
    .call-out-right{
        @include padding(1rem,2rem,1rem,2rem);
    }

    button{
        margin-top: 5rem;
    }

}




.about-featured-project{
    background-image: linear-gradient(rgba(16,16,16, 0.5), rgba(16,16,16, 0.5)), url("../images/img-feat-proj-bg.jpg");
  
    /* Set a specific height */
    //height: 742px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.feat-proj-imgs{
    @include make-container(1644px);
    .feat-proj-img{
        &:nth-of-type(1){
            margin-right:7rem;
        }
        &:nth-of-type(2){
            margin-top:4rem;
        }
    }
}
.feat-proj-footer{
    @include make-container(1644px);
    height: 345px;
    padding-top: 80px;
    h2{
        @include fontSize(50px);
        color: $white;
        font-family: $font-family-secondary;
        line-height: 1.2;
    }
}