


// .slider-wrapper,
// .slider {
//     position: relative;
//     width: 100%;
//     height: 100vh;
//     max-height: 930px;
// }

// .slider-wrapper {
//     display: block;
//     background: #FFF;
//     overflow: hidden;

//     top: 0;
//     bottom: 0;

// }

// .slider {
//     width: 100%;
//     height: 100%;
//     position: relative;

//     opacity: 1;
//     z-index: 0;

//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;

//     // align-items: center;
//     // justify-content: center;
//     // align-content: center;

//     transition: -webkit-transform 1600ms, transform 1600ms;
//     transform: scale(1);
// }

// /* each slide styles */
// .slide1 {
//     background: #00bcd7;
//     left: 0;
// }

// .slide2 {
//     background: #009788;
//     left: 100%
// }

// .slide3 {
//     background: #ff5608;
//     left: 200%
// }

// .slide4 {
//     background: #607d8d;
//     left: 300%;
// }

// /* slider-pagination */
// .slider-pagination {
//     position: absolute;
//     bottom: 20px;
//     width: 100%;
//     left: 0;
//     text-align: center;
//     z-index: 1000;
// }

// .slider-pagination label {
//     width: 10px;
//     height: 10px;
//     border-radius: 50%;
//     display: inline-block;
//     background: rgba(255, 255, 255, 0.2);
//     margin: 0 2px;
//     border: solid 1px rgba(255, 255, 255, 0.4);
//     cursor: pointer;
// }

// /* Next and previous button*/
// .control {
//     position: absolute;
//     top: 50%;
//     width: 50px;
//     height: 50px;
//     margin-top: -25px;
//     z-index: 55;
// }

// .control label {
//     z-index: 0;
//     text-align: center;
//     line-height: 50px;
//     font-size: 50px;
//     color: #FFF;
//     cursor: pointer;
// }

// .control label:hover {
//     opacity: 0.8;
// }

// .next {
//     right: 1%;
// }

// .previous {
//     left: 1%;
// }

// /* Slider control sliding effect */
// .slide-radio1:checked~.slider {
//     -webkit-transform: translateX(0%);
//     transform: translateX(0%);
// }

// .slide-radio2:checked~.slider {
//     -webkit-transform: translateX(-100%);
//     transform: translateX(-100%);
// }

// .slide-radio3:checked~.slider {
//     -webkit-transform: translateX(-200%);
//     transform: translateX(-200%);
// }

// .slide-radio4:checked~.slider {
//     -webkit-transform: translateX(-300%);
//     transform: translateX(-300%);
// }


// /* next and previous will be  active when slider moving*/
// .slide-radio1:checked~.next .numb2,
// .slide-radio2:checked~.next .numb3,
// .slide-radio3:checked~.next .numb4,
// .slide-radio2:checked~.previous .numb1,
// .slide-radio3:checked~.previous .numb2,
// .slide-radio4:checked~.previous .numb3 {
//     display: block;
//     z-index: 1
// }

// /* css for active current pagination */
// .slide-radio1:checked~.slider-pagination .page1,
// .slide-radio2:checked~.slider-pagination .page2,
// .slide-radio3:checked~.slider-pagination .page3,
// .slide-radio4:checked~.slider-pagination .page4 {
//     background: rgba(255, 255, 255, 1)
// }

// .slider-wrapper {
//     &.slider-project {

//         .slider {
//             text-align: left;
//             flex-flow: wrap-reverse;
//             justify-content: flex-start;
//             align-items: stretch;
//             align-content: stretch;
//         }

//         .slide1 {
//             background: bg-colors("red-background");
//             left: 0;
//         }

//         /* Next and previous button*/
//         .control {
//             position: absolute;
//             top: 45%;
//             width: 44px;
//             height: 50px;
//             margin-top: -15px;
//             z-index: 55;

//             img {
//                 width: 43px;
//             }

//             @media (min-width: 992px) {
//                 top: 50%;
//                 width: 50px;
//                 height: 50px;
//                 margin-top: -25px;

//                 img {
//                     width: 88%;
//                 }
//             }
//         }

//         .next {
//             right: 1%;

//             @media (min-width: 992px) {
//                 right: 3%;
//             }

//         }

//         .previous {
//             left: 1%;

//             @media (min-width: 992px) {
//                 left: 3%;
//             }
//         }

//         .project-slide-rgt,
//         .project-slide-lft {


//             @media (min-width: 992px) {
//                 max-width: 50%;
//             }
//         }

//         .project-slide-lft {
//             padding: 3rem;
//             height: 30%;

//             @media (min-width: 992px) {
//                 padding: 6rem;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: flex-end;
//             }

//             .project-detail {
//                 position: relative;
//                 top: 40rem;
//             }
//         }

//         .project-slide-rgt {
//             background-image: url(../images/img-ourwwork-right.jpg);
//             /* Set a specific height */
//             height: 70%;
//             background-position: top left;
//             background-repeat: no-repeat;
//             background-size: cover;
//             position: relative;
//             width: 100%;

//             display: flex;
//             flex-direction: column;
//             justify-content: flex-end;

//             @media (min-width: 992px) {
//                 height: 100%;
//             }
//         }

//     }
// }


// .slider-wrap {
//     height: 100%;
//     width: 100%;
//     position: relative;
//     overflow: hidden;
//     background: #101010;
//     color: white;

//     input {
//         z-index: 999;
//         position: relative;
//     }

//     .slide {
//         height: 100%;
//         width: 100%;
//         position: absolute;
//         top: 0;
//         z-index: 10;
//         padding: 8em 1em 0;
//         background-position: 50% 50%;
//         background-size: cover;

//         &-one {
//             background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/65463/starryFarm.jpg');
//         }

//         &-two {
//             background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/65463/campusDarkDays.jpg');
//         }

//         &-three {
//             background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/65463/autumn.jpg');
//         }

//         &-four {
//             background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/65463/lakehouse.jpg');
//         }
//     }

//     [id^="slide"]:checked+.slide {
//         left: 0;
//         z-index: 100;
//     }

//     div#Arrows {
//         position: relative;
//         z-index: 1001;
//     }

// }

/*----------------------------------------------------------*/
/*------------------BRICKS custom Slider--------------------*/
/*----------------------------------------------------------*/
.bcg-slider {

    display: inline-block;
    text-align: left;
    position: relative;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .bcg-slide {
        height: 100%;
        width: 98%;
        padding: 4em 0;




        .slide-inner {
            .subscriptions-slider & {
                @media #{$mq-desktop-up} {
                    @include make-container(88%);
                }
            }
        }

        &.slide-item {

            &-1 {
                .subscriptions-slider & {
                    background-image: none;

                    @media #{$mq-tablet-up} {
                        @include img-replace('../images/bg-brighter-business.jpg', 100%, 100%, bottom, contain, $disp: block);
                    }

                    @media #{$mq-desktop-up} {
                        @include img-replace('../images/bg-brighter-business.jpg', 100%, 100%, 50% 50%, $disp: inline-block);
                    }

                }

            }

            &-2 {


                .subscriptions-slider & {
                    //background-image: none;

                    @media #{$mq-tablet-up} {
                        @include img-replace('../images/bg-brighter-business.jpg', 100%, 100%, bottom, contain, $disp: block);
                    }

                    @media #{$mq-desktop-up} {
                        @include img-replace('../images/bg-brighter-business.jpg', 100%, 100%, 50% 50%, $disp: inline-block);
                    }

                }



                // .subscriptions-slider & {
                //     background-image: none;

                //     @media #{$mq-phone-up} {
                //         @include img-replace('../images/bg-bricks-builder.jpg', 100%, 100%, right bottom, $disp: inline-block);
                //     }

                //     @media #{$mq-desktop-up} {
                //         @include img-replace('../images/bg-bricks-builder.jpg', 100%, 100%, right bottom, $disp: inline-block);
                //     }
                // }
            }

            &-3 {
                .subscriptions-slider & {
                    background-image: none;

                    @media #{$mq-tablet-up} {
                        @include img-replace('../images/bg-bricks-booster.jpg', 100%, 100%, bottom, contain, $disp: block);
                    }

                    @media #{$mq-desktop-up} {
                        @include img-replace('../images/bg-bricks-booster.jpg', 100%, 100%, 98% bottom,contain,  $disp: inline-block);
                    }
                }
            }

        }

        .slide-content {
            .subscriptions-slider & {
                @include make-container(100%);
                color: text-colors("white");
                .slide-body {
                    margin-top: 2rem;
                }
                @media #{$mq-tablet-up} {
                    @include make-container(70%, $mar-lft: 1rem);
                }

                @media #{$mq-desktop-up} {
                    @include make-container(30%, $mar-lft: 5rem);

                    .slide-body {
                        @include make-container(90%, $mar-lft: 4rem);
                        margin-top: 7rem;
                        margin-bottom: 3rem;
                    }
                }
            }
        }
    }


}

.bcg-slider>input {
    display: none;
}

.bcg-slider>input:nth-of-type(10):checked~ul li:first-of-type {
    margin-left: -900%;
}

.bcg-slider>input:nth-of-type(9):checked~ul li:first-of-type {
    margin-left: -800%;
}

.bcg-slider>input:nth-of-type(8):checked~ul li:first-of-type {
    margin-left: -700%;
}

.bcg-slider>input:nth-of-type(7):checked~ul li:first-of-type {
    margin-left: -600%;
}

.bcg-slider>input:nth-of-type(6):checked~ul li:first-of-type {
    margin-left: -500%;
}

.bcg-slider>input:nth-of-type(5):checked~ul li:first-of-type {
    margin-left: -400%;
}

.bcg-slider>input:nth-of-type(4):checked~ul li:first-of-type {
    margin-left: -300%;
}

.bcg-slider>input:nth-of-type(3):checked~ul li:first-of-type {
    margin-left: -200%;
}

.bcg-slider>input:nth-of-type(2):checked~ul li:first-of-type {
    margin-left: -100%;
}

.bcg-slider>input:nth-of-type(1):checked~ul li:first-of-type {
    margin-left: 0%;
}

.bcg-slider>ul {
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: auto;
    z-index: 1;

    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;

    // @media #{$mq-tablet-up} {
        
    // }
    @media #{$mq-desktop-up} {
        margin: 0 auto;
        height: 100%;
    }


}

.bcg-slider>ul>li {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 15px;
    font-size: initial;
    line-height: normal;
    transition: all 0.5s cubic-bezier(0.4, 1.3, 0.65, 1);
    vertical-align: top;
    box-sizing: border-box;
    white-space: normal;
}

.bcg-slider>ul>li.scrollable {
    overflow-y: scroll;
}

.bcg-slider>.navigation {
    position: relative;
    top: -7%;
    left: 50%;
    z-index: 10;
    margin-bottom: -10px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    user-select: none;

    @media #{$mq-tablet-up} {
        top: -3rem;
        left: 21rem;
    }
    @media #{$mq-desktop-up} {
        top: 73%;
    left: 13%;
    }
}

.bcg-slider>.navigation>div {
    margin-left: -100%;
}

.bcg-slider>.navigation label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 4px;
    padding: 4px;
    background: transparent;
    border: 1px $red solid;
}

.bcg-slider>.navigation label:hover:after {
    opacity: 1;
}

.bcg-slider>.navigation label:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -6px;
    margin-top: -6px;
    border-radius: 50%;
    padding: 6px;
    opacity: 0;
    background: $red;
}

.bcg-slider>.arrows {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bcg-slider.inside .navigation {
    bottom: 10px;
    margin-bottom: 10px;
}

.bcg-slider.inside .navigation label {
    border: 1px solid #7e7e7e;
}

.bcg-slider>input:nth-of-type(1):checked~.navigation label:nth-of-type(1):after,
.bcg-slider>input:nth-of-type(2):checked~.navigation label:nth-of-type(2):after,
.bcg-slider>input:nth-of-type(3):checked~.navigation label:nth-of-type(3):after,
.bcg-slider>input:nth-of-type(4):checked~.navigation label:nth-of-type(4):after,
.bcg-slider>input:nth-of-type(5):checked~.navigation label:nth-of-type(5):after,
.bcg-slider>input:nth-of-type(6):checked~.navigation label:nth-of-type(6):after,
.bcg-slider>input:nth-of-type(7):checked~.navigation label:nth-of-type(7):after,
.bcg-slider>input:nth-of-type(8):checked~.navigation label:nth-of-type(8):after,
.bcg-slider>input:nth-of-type(9):checked~.navigation label:nth-of-type(9):after,
.bcg-slider>input:nth-of-type(10):checked~.navigation label:nth-of-type(10):after,
.bcg-slider>input:nth-of-type(11):checked~.navigation label:nth-of-type(11):after {
    opacity: 1;
}

.bcg-slider>.arrows {
    position: relative;
    bottom: 4%;   
    width: 91%;
    height: 43px;
    padding: 0;
    z-index: 2;
    box-sizing: content-box;

    @media #{$mq-tablet-up} {
        display: block;
    }
    @media #{$mq-desktop-up} {
      position: absolute;  
      top: 50%; 
      height: 62px; 
    }    
}

.bcg-slider>.arrows label {



    @include img-replace('#{$path--rel}/icon-next.png', 43px, 43px, center, contain, inline-block);
    display: none;
    position: absolute;
    padding: 13px;
    top: 0;
    cursor: pointer;

    @media #{$mq-tablet-up} {
       
    }
    @media #{$mq-desktop-up} {
        @include img-replace('#{$path--rel}/icon-next.png', 62px, 62px, center, contain, none);
       top: -19rem; 
    }
}

.bcg-slider>.arrows label:hover {

    margin: 0 0px;
}

.bcg-slider>.arrows label:before {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    height: 300%;
    width: 300%;
}

.bcg-slider.infinity>input:first-of-type:checked~.arrows label.goto-last,
.bcg-slider>input:nth-of-type(1):checked~.arrows>label:nth-of-type(0),
.bcg-slider>input:nth-of-type(2):checked~.arrows>label:nth-of-type(1),
.bcg-slider>input:nth-of-type(3):checked~.arrows>label:nth-of-type(2),
.bcg-slider>input:nth-of-type(4):checked~.arrows>label:nth-of-type(3),
.bcg-slider>input:nth-of-type(5):checked~.arrows>label:nth-of-type(4),
.bcg-slider>input:nth-of-type(6):checked~.arrows>label:nth-of-type(5),
.bcg-slider>input:nth-of-type(7):checked~.arrows>label:nth-of-type(6),
.bcg-slider>input:nth-of-type(8):checked~.arrows>label:nth-of-type(7),
.bcg-slider>input:nth-of-type(9):checked~.arrows>label:nth-of-type(8),
.bcg-slider>input:nth-of-type(10):checked~.arrows>label:nth-of-type(9),
.bcg-slider>input:nth-of-type(11):checked~.arrows>label:nth-of-type(10) {
    @include img-replace('#{$path--rel}/icon-prev.png', 43px, 43px, center, contain, inline-block);
    display: block;
    left: 3.2rem;
    right: auto;
    bottom: 0;

    // @media #{$mq-tablet-up} {
        
    // }
    @media #{$mq-desktop-up} {
        @include img-replace('#{$path--rel}/icon-prev.png', 62px, 62px, center, contain, inline-block);
        display: block;
        left: 10.4375rem;
        right: auto;
        top: 10rem;;
    }


}

.bcg-slider.infinity>input:last-of-type:checked~.arrows label.goto-first,
.bcg-slider>input:nth-of-type(1):checked~.arrows>label:nth-of-type(2),
.bcg-slider>input:nth-of-type(2):checked~.arrows>label:nth-of-type(3),
.bcg-slider>input:nth-of-type(3):checked~.arrows>label:nth-of-type(4),
.bcg-slider>input:nth-of-type(4):checked~.arrows>label:nth-of-type(5),
.bcg-slider>input:nth-of-type(5):checked~.arrows>label:nth-of-type(6),
.bcg-slider>input:nth-of-type(6):checked~.arrows>label:nth-of-type(7),
.bcg-slider>input:nth-of-type(7):checked~.arrows>label:nth-of-type(8),
.bcg-slider>input:nth-of-type(8):checked~.arrows>label:nth-of-type(9),
.bcg-slider>input:nth-of-type(9):checked~.arrows>label:nth-of-type(10),
.bcg-slider>input:nth-of-type(10):checked~.arrows>label:nth-of-type(11),
.bcg-slider>input:nth-of-type(11):checked~.arrows>label:nth-of-type(12) {
    display: block;
    right: 1rem;
    left: auto;
}