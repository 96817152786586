.bricks-platform-coming-soon-message{
    header{
        .jumbotron{
            background: transparent;
            border-radius: 0;
            margin-top: 75px;
            .inner{
                h1{
                    font-family: $alt-heading-font;
                    margin-bottom: .5rem;
                }
                h3{
                    margin-bottom: 1rem;
                    &.lead{
                        font-size: 2.5rem;
                    }
                    &.cover-heading{
                        color: text-colors("red");
                        font-size: 5rem
                    }
                }
                p{
                    width: 29%;
                    text-align: start;
                    margin-inline-start: 35%;  
                    font-size: 1.1rem;
                }
            }




        }        
    }

}