form {
    &.form-inline {
        &.modal-sign-up {
            @include fontSize(20px);
            font-family: $font-family-secondary;
            width: 100%;

            .form-control {
                border: none;
                border-radius: 0;
                color: $white;
                opacity: 1;
                border-bottom-left-radius: 1.25rem;
                padding-left: 2.5rem;
            }

            input {
                width: 42.3125rem;
                height: 5.3125rem;
                background: #373737;

            }

            button {
                height: 5.3125rem;
                width: 12.5rem;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 20px;
            }

            .btn {
                border: none;
                box-shadow: none;

                &:active {
                    background: #373737;
                }
            }
        }
    }

    &.sign-up-form {
        @include make-container(868px);
        .form-control{
            width:20rem;
        }
        select {
            &.form-control {
                background-color: #101010;
                color: text-colors("white");
                border: 1px solid $red;

                /* Here's the code we need */

                appearance: none;
                option{
                    &:hover,
                    &:focus,
                    &:active,
                    &:checked{
                        background-color: $red!important;
                    }
                }
            }
        }

        .select-custom {
            position: relative;
        }

        .select-custom:after {
            content: ". ";
            font-size: 7.3125rem;
            position: absolute;
            top: .75rem;
            right: 1.125rem;;
            color: #101010;
            pointer-events: none;
            background: url(../images/icon-dropdown-red.png) no-repeat;
        }
        button{
            margin-top: 1rem;
        }
    }
}
