footer {
    @include fontSize(14px);
    color: text-colors('white');


    h5 {
        @include fontSize(14px);
        color: text-colors('white');
        text-transform: uppercase;
        font-family: $alt-heading-font;
        margin-bottom: 1rem;
        letter-spacing: 1.2px;
    }

    a {
        color: text-colors('brownish-grey');

        &:hover {
            color: text-colors('white');
        }
    }

    &.footer {
        margin-top: 3rem;

        @media #{$mq-tablet-up} {
            margin-top: 6rem;
        }
    }

    .footer-top {
        color: text-colors('brownish-grey');
        margin: 1rem;

        @media #{$mq-tablet-up} {
            margin-bottom: 3.5rem;
            margin: 2rem;

        }




        .company-links {
            @include margin(initial, auto, 2rem, auto);

            ul {
                margin-top: 3.3rem;
            }

            li {
                margin-bottom: 3rem;
            }

            @media (min-width: 768px) {
                margin: 0;
            }
        }

        .instagram-widget {
            padding-left: 0;
            margin-left: 0;
            // @media #{$mq-tablet-up} {


            // }

            @media #{$mq-desktop-up} {
                margin-top: 3rem;

                h5 {
                    margin-left: 3rem;
                }

                ul {
                    margin-left: 3rem;
                    width: 22rem;
                    margin-right: auto;
                }

                li {
                    margin-bottom: 1rem;
                }
            }
        }




    }

    .footer-middle {
        margin: 2rem;

        .brand-footer {



            @media #{$mq-tablet-up} {

                @include fontSize(55px);
                font-family: $alt-heading-font;
                letter-spacing: 2.1px;
                line-height: 1.2;

                span {
                    border-bottom: $red 4px solid;
                    padding-bottom: .8rem;
                    display: inline-block;
                }

                @include media-breakpoint-down(md) {
                    text-align: left;

                }

            }


        }

        .social {

            margin-left: 3rem;
            text-align: right;

            ul {
                li {
                    margin-left: 2.5rem;
                }
            }

            @include media-breakpoint-down(md) {
                text-align: left;
                margin-top: 1.75rem;
                margin-left: auto;
                margin-right: auto;

                ul {
                    li {
                        &:first-of-type {
                            margin-left: 0;
                        }

                    }
                }
            }
        }

        .about-footer {

            margin-top: 2rem;
            margin-bottom: 2rem;


            p {
                color: text-colors("light-grey");
            }

        }

        .row {
            &:first-of-type {
                margin-bottom: 40px;
            }
        }

        @include media-breakpoint-down(md) {


            .submit-inquiry-footer {
                @include margin (1rem, auto, 1rem, auto);

                @media #{$mq-tablet-up} {
                    margin-bottom: 2rem;
                }

            }
        }
    }

    &.footer-bottom {
        background: bg-colors("white-background");
        color: text-colors("brownish-grey");
        font-family: $font-family-secondary;
        margin-top: 0;
        padding: 1rem 0;

        ul {
            width: 60%;

            li {
                float: left;
                padding: 0 12px;
                width: 20%;
                text-align: center;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                padding-left: 0;

                li {
                    padding: 0;
                    width: 33%;
                    white-space: nowrap;
                }
            }
        }

        a {
            &:hover {
                color: text-colors("brownish-grey");
            }
        }

        .copyright {
            text-align: right;
            //padding-right: 3rem;
            margin: 0;

            @include media-breakpoint-down(md) {
                @include fontSize(12px);
                text-align: center;

            }

            @include media-breakpoint-down(sm) {
                text-align: left;

            }
        }
    }
}