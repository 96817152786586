
html,
body{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  background: $page_bg_color;
  font-size: $base-font-size;
}


.section-container,
.content-container {
  @include make-container(1920px);
  position: relative;
}