// ---- BRICKS Custom color Palettes ---- 
$white: #fff;
$black: #111;
$red: #ed1c24;
$grey: #6a6a6a;
$dark-grey: #373737;
/*--------------------------------*/
$darkGrey: #1D1D1D;
$lightGrey: #D3D3D3;
$black: #000;
$main-copy-color: $white;
$offBlack: #101010;
$transparent: transparent;
$page_bg_color: $offBlack;

$bg-colors: ();
$bg-colors: map-merge(("white-background":$white,
"black-background":$black,
"red-background": $red,
"grey-background":$grey,
"dark-grey-background":$dark-grey,

/*--------------------------------*/

"light-grey-background":#f3f2f2,
"page-background": #101010,
"card-background": #1d1d1d,
"off-black": $offBlack,
"header-background":$black,
"brownish-grey": #5f5e5e,
"mid-grey-background":#d3d3d3,
"off-white": #F3f2f2,
), $bg-colors);

$text-colors: ();
$text-colors: map-merge(("white-copy":$white,
"black-copy":$black,
"red-copy":$red,
"grey-copy":$grey,
"dark-grey-copy":$dark-grey,

/*--------------------------------*/

"off-white": #F3f2f2,
"lighter-grey": #F1F1F1,
"light-grey": #707070,
"brownish-grey": #5f5e5e,
"medium-grey": #535353,
"mid-grey": #c3c3c3,
"dark-grey": #2C2C2C,
"darker-grey": #202020,
"gold": #967840,
"blue": #0477AA,
"red": #EA1A28,
"white": #fff,
"off-black": #101010,
"header-primary": #101010,
"header-secondary":#6c6c6c,

), $text-colors);


//typograhy and fonts
$fa-font-path: "../webfonts";

$font-family-primary: 'primeregular',
Arial,
"Helvetica Neue",
Helvetica,
sans-serif;
;
$font-family-secondary:'primelight',
Arial,
"Helvetica Neue",
Helvetica,
sans-s erif;
;






$alt-heading-font: $font-family-secondary;

//Paths to images
$path--rel : "../images";


$base-font-size: 1rem;
$base-line-height: 1.43;
$header-line-height: 1.18;



$phone-small: 20rem; //320px;
$phone: 25rem; //400px;
$phone-wide: 30rem; //480px
$phablet: 35rem; //560px;
$tablet-small: 40rem; //640px;
$tablet: 48rem; //768px
$tablet-wide: 64rem; //1024px;
$laptop-small: 78rem; //1248px;
$ipad-pro-p: 64rem; //1024px;
$ipad-pro-ls: 83.5rem; //1366px;
$desktop-small: 90rem; //1440px
$desktop: 120rem; //1920px
$desktop-lrg: 160rem; //2560px


// Media Queries
$mq-xxsmall: "(max-width: #{$phone-small})";
$mq-small-phone: "(min-width: #{$phone-small}) and (max-width: #{$phone-wide} - 1)";
$mq-phone: "(min-width: #{$phone-wide}) and (max-width: #{$tablet-small} - 1)";
$mq-tablet :"(min-width: #{$tablet-small}) and (max-width: #{$tablet-wide} - 1)";
$mq-laptop :"(min-width: #{$tablet-wide}) and (max-width: #{$desktop-small} - 1)";
$mq-small-desktop :"(min-width: #{$desktop-small}) and (max-width: #{$desktop} - 1)";
$mq-desktop :"(min-width: #{$desktop})";

$mq-phone-up :"(min-width: #{$phone-wide})";
$mq-tablet-down :"(max-width: #{$tablet-small} - 1)";
$mq-tablet-up :"(min-width: #{$tablet-small})";
$mq-desktop-up :"(min-width: #{$ipad-pro-p})";


$bp-xsmall-p: 18.75rem; // 300
$bp-xsmall-l: 30rem; // 480
$bp-small: 48rem; // 768px
$bp-medium: 64rem; // 1024px
$bp-large: 85.375rem; // 1366px
$bp-xlarge: 120rem; // 1920px
$bp-xxlarge: 128.625rem; // 2560px 





// Media Queries
$mq-xsmall-p: "(max-width: #{$bp-xsmall-p} - 1)";
$mq-xsmall-l: "(min-width: #{$bp-xsmall-p}) and (max-width: #{$bp-xsmall-l} - 1)";
$mq-small:"(min-width: #{$bp-xsmall-l}) and (max-width: #{$bp-small} - 1)";
$mq-medium: "(min-width: #{$bp-small}) and (max-width: #{$bp-medium} - 1)";
$mq-large: "(min-width: #{$bp-medium}) and (max-width: #{$bp-large} - 1)";
$mq-xlarge: "(min-width: #{$bp-large}) and (max-width: #{$bp-xlarge} - 1)";
$mq-xxlarge: "(min-width: #{$bp-xlarge}) and (max-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";









// $phone-port: "(max-width: (#{$bp-xsmall-l} - 1)";
// $phone-land: "(min-width: #{$bp-xsmall-l}) and (max-width: #{$bp-small} - 1)";
// $tablet: "(min-width: #{$bp-small}) and (max-width: #{$bp-medium} - 1)";
// $laptop: "(min-width: #{$bp-medium}) and (max-width: #{$bp-large} -1)";
// $desktop-small: "(min-width: #{$bp-large}) and (max-width: #{$bp-xlarge} -1)";