.nav-tabs{
    .benefits-table & {        
        .nav-item{
            @include fontSize(12px);            
            border: none;
            border-radius: 0;
            font-family: $alt-heading-font;
            color: text-colors("header-secondary");
            background: bg-colors("mid-grey-background");
            width: 150px;
            span{
                @include fontSize(15px);
                display: block;
                text-align: center;
            }
            &.active{
                background-color: bg-colors("red-background");
                color: text-colors("white");
                border-color: initial;
                border: none;
                
            }
        }
        #nav-business-tab{
            border-top-left-radius: 1rem;
            white-space: nowrap;
        }
        #nav-booster-tab{
            border-top-right-radius: 1rem;
        }
    }   
}
.tab-content{
    .benefits-table & {
        @include fontSize(15px);
        font-family:$font-family-primary;
        background: bg-colors("mid-grey-background");
        color: text-colors("light-grey");
        line-height: 1.2;
        letter-spacing:1.2px;
        th{
            height: 44px;
            line-height: 2.5;
            &.benefits-included{
                width: 150px;
                text-align: center;
            }
        }
    }
}

/*Business Subscription Table*/
.business-subscription-table{
    font-family: $font-family-primary; 
    th{
        @include fontSize(15px); 
        color: text-colors("light-grey");
        font-weight: normal;        
        line-height: 2.5;
        letter-spacing: .5px; 

    }  
    td{
        @include fontSize(20px);
        color: text-colors("off-black"); 
        text-align: center;
        .fas{
            @include fontSize(33px);  
        }
    }
    tfoot{
        th,td,a{
            @include fontSize(10px);   
            color: text-colors("red");           
        }
        td{
            span{
                display: block;
                margin-top: .8rem;
            }
        }


    }
    thead{        
        th{
            @include fontSize(20px);
            color: text-colors("off-black");
            text-align: center;
            line-height: 1.2;
            &:first-of-type{
                @include fontSize(25px);
                text-align: left;
                color: text-colors("light-grey");
                white-space: nowrap;
            }

        }        
    }

}