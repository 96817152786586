.marketing-cards-horizontal {
    @include make-container(85%, $transparent);

    .how-it-works & {
        @include make-container(75%, $transparent);
        margin-top: 7rem;

        @include media-breakpoint-down(md) {
            @include make-container(100%, $transparent);
            margin-top: 3rem;
        }

        @media #{$mq-desktop-up} {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.card-deck {
    .how-it-works & {
        margin-bottom: 12rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 8rem;
        }
    }
}

.card {
    .how-it-works & {
        background-color: transparent;

        .card-body {
            padding: 0 2rem;

            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
    }

    .marketing-cards-horizontal & {
        border: none;

        .subscription-lead & {
            padding: 0px 6rem 4rem;
        }

        .fas,
        .fab {
            @include fontSize(55px);
        }

        h3 {
            @include fontSize(18px);
            color: text-colors('light-grey');
            letter-spacing: .5px;
            line-height: 1.18;
            margin-top: 3rem;


            .how-it-works & {
                @extend .heading-level-2;
                margin-top: 2rem;
                font-family: $font-family-primary;

                @include media-breakpoint-down(md) {
                    @include fontSize(28.8px);
                }
            }
        }

        p {
            @include fontSize(16px);
            color: text-colors('light-grey');
            letter-spacing: .4px;
            line-height: 1.57;
            font-family: $font-family-secondary;

            .how-it-works & {
                @extend .lead-level-2;
                font-family: $font-family-secondary;

                @include media-breakpoint-down(md) {
                    @include fontSize(18.4px);
                }
            }
        }

        .card-title {
            margin-bottom: 2rem;
        }
    }
}

.marketing-cards-vertical {
    @include make-container(1085px, $transparent);
    margin-left: 56px;
    color: text-colors("header-primary");

    .mcv {
        margin-bottom: 6rem;

        &:first-of-type {
            margin-top: 6rem;
        }
    }

    .card-body,
    .card {
        border: none;
    }

    h2,
    h5 {
        font-family: $alt-heading-font;
    }

    h5 {
        @include fontSize(15px);
        margin-top: 1.3rem;
    }

    .fas {
        @include fontSize(124px);
        color: text-colors("white");
        line-height: 1.21;
        letter-spacing: 4.7px;

    }

    .mcv-l {
        width: 326px;
        height: 280px;
        background: bg-colors("red-background");

        p {
            margin-left: .5rem;
        }

        span {
            @include fontSize(25px);
            position: relative;
            top: -2rem;
            color: text-colors("white-copy");
        }
    }

    .mcv-r {
        width: 647px;
        height: 280px;
        background: bg-colors("off-white");
        margin-left: -21px;

        .card-title,
        .card-body {
            letter-spacing: .7px;
            color: text-colors("black-copy");

            ul {
                li {
                    @include fontSize(19px);
                    letter-spacing: .7px;
                    line-height: 2;
                    font-family: $font-family-secondary;
                }
            }


        }
    }
}

.subscription-benefits-cards {
    padding-bottom: 15rem;
}