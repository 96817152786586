.modal{
    &.signup-modal{
        top: 8%;
        .modal-dialog{
            @media (min-width: 576px) {
                max-width: 900px;
            }
        }
        .modal-content{
            background-color: transparent;
            border:none;
        }
        .modal-header{
            border: none;
            .close{
                @include fontSize(60px);
                color: $red;
                text-shadow: none;  
                padding: 0;
                margin: 0 -2rem -2rem auto;             
            } 
        }
        .modal-body{
            @include img-replace('signUpBg2x.jpg', 877px, 427px, center, cover, inline-block);
            color:text-colors("white");
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .mail-list-request{
                @include padding(0, 12%, 0, 12%);
                @include margin(40%, null, null, null);
            }
        }
        .modal-footer{
            border: none;
            padding: 0;
            width: 877px;
        }
    }
}