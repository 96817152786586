.btn {
    //shared CTA button styles
    @include fontSize(15px);
    @include padding(1rem, 3.3125rem, 1rem, 3.3125rem);
    border-radius: .25rem;
    white-space: nowrap;

    &.btn-primary {
        //red CTA button
        background-color: $red;
        color: $white;
        border: none;

        &:hover,
        &:active {
            background-color: $red;
            color: $white;
            border: 0;
        }

        &:active {
            border: none;
        }

        .footer-middle & {
            @include fontSize(13px);
            font-family: $font-family-secondary;
            border-radius: .9375rem .9375rem 0 0;
            width: 9.75rem;
            height: 3.75rem;
            line-height: 1.38;

            @media screen and (min-width: $bp-xsmall-p) and (max-width: $bp-xsmall-l) {
                height: 4rem;
                width: 12rem;
            }
        }

        .business-subscription-table & {
            @include fontSize(14px);
            @include padding(.61rem, 1.9rem, .61rem, 1.5rem);
            margin-top: 1.7rem;
        }

        .slide-footer & {
            @include fontSize(15px);
            @include padding(1.1rem, 3rem, 1.1rem, 3rem);
            text-transform: capitalize;
            font-family: $font-family-secondary;
        }

        .subscription-select & {
            @include margin(8rem, auto, 8rem, auto);
            display: block;
            max-width: 16.25rem;
        }

        .subscriptions-nav-bar & {
            @include fontSize(12px);
        }
    }

    //grey CTA button
    &.btn-secondary {
        background-color: #E8E8E8;
        color: $black;

        &:hover {
            background-color: #E8E8E8;
            color: $black;
            border: 0;
        }
    }

    &.btn-outline-secondary {
        border-color: $white;
        border-width: .125rem;

        .footer-middle & {
            @include fontSize(10px);
            color: text-colors("white");
            height: 3.5rem;
            width: 10.5rem;
            border-color: $red;
            border-radius: 2.75rem;
            font-family: $font-family-secondary;
                letter-spacing: .2125rem;
            @media (min-width: 768px) {
                @include fontSize(13px);
                height: 4.5rem;
                width: 11.5rem;
                line-height: 1.77;
            }

        }
    }

    &.log-in,
    &.sign-up {
        @extend .btn;
        @include padding(.6875rem, 1.5625rem, .6875rem, 1.5625rem);
        width: 100px;
        height: 40px;

        @include media-breakpoint-down(lg) {
            margin-top: 2rem;

        }
    }

    &.log-in {
        margin-right: 2.5rem;

        @include media-breakpoint-down(lg) {

            margin-left: 2rem;
        }
    }

    &.sign-up {
        @include padding(.5625rem, 1.25rem, .5625rem, 1.25rem);
        color: text-colors("white");

    }

    .agency-exp & {
        align-self: flex-start;
    }

    &.submit-inquiry {
        @include padding(1rem, 1.8125rem, 1rem, 1.8125rem);
    }

    @include media-breakpoint-down(sm) {
        @include padding(.5rem, 1.25rem, .5rem, 1.25rem);
    }
}